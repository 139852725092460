// @ts-ignore
import list from "@sirius/smt-api-spec/src/clusters/schema/ClustersKeyslist.json";

type ClustersKEY = Uppercase<Clusters.Key>

type DNT = {
    [k in ClustersKEY]: Lowercase<k>;
};
/**
 * DEPLOY_NAME is a special map for cluster dependent guards
 * @file @sirius/smt-api-spec/src/clusters/schema/ClustersKeyslist.json
 * @readonly
 * @example
 *   if (cluster.key === DEPLOY_NAME.CPM) {
 *       ...
 *   }
 *
 */
const DEPLOY_NAME = list.reduce(
    (acc: DNT, k) => ({
        ...acc,
        ...{[k.toUpperCase()]: k.toLowerCase()}
    }), {} as DNT
);

export {DEPLOY_NAME};

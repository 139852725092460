import {cluster}     from "CheopsRoot/clusters/current";
export {DEPLOY_NAME} from "Cheops/../clusters/dnt";

export const META_THEME_COLOR_DEFAULT = "#6c4dc4";

export const COURSE_LOCKED_BG_COLOR = "#3E3D40";

export const REVIEW_STATUS = {
    ON_REVIEW: "onReview",
    REVIEWED: "reviewed",
    READ: "read",
};

export const YANDEX_METRIKA_CHEOPS = {
    options: {
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        trackHash: true,
    },
};

export const GRAPH_EXT_RELATION_SPACE = 55;


export const REPORT_BUG_LVL = {
    WARNING: "warning",
    ERROR: "error",
    CRITICAL: "critical",
    ALERT: "alert",
};

export const REPORT_INITIATOR = {
    FRONT: "front",
    BACK: "back",
};


export const COPYRIGHT_TEXT = "© 2019-2020 Сириус";
export const COPYRIGHT_CPM_TEXT = "© ЦПМ";

export const PROGRAMMING_ANSWER_FILE_SIZE_LIMIT_BYTES = 204800;

export const ELEMENT_VERDICT = {
    OK: "ok",
    WRONG: "wrong",
    PARTLY: "partly",
    NOTREADY: "notReady",
    ANNULLED: "annulled",
    NONE: "none",
};

export const RESULTS_TABLE_MAX_USER_COUNT = 150;
export const RESULTS_TABLE_UNCUTTABLE_PERCENT = 5;


export const MAX_TASK_CONTENT_WIDTH = 710;

export const LANDING_COURSE_TYPE = {
    OPENED: "opened",
    INVITES: "invites",
    MY: "my",
    CLOSED: "closed"
}

export const hasNotifications = cluster?.hasNotifications ?? true;

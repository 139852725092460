import ClustersKeys    from "@sirius/smt-api-spec/src/clusters/schema/ClustersKeyslist.json";
// @ts-ignore
import data from "@sirius/smt-api-spec/src/clusters/values.json";

const _k = `${CONFIG.DeployName}`.toLowerCase();

if (  ClustersKeys.indexOf(_k) === -1 ) {
    throw new Error(`Unknown cluster ${_k}`)
}

export type ClustersDict = {
    [k in Clusters.Key]: Clusters.Cluster
}


const key:     Clusters.Key        = _k as Clusters.Key;
const values:  ClustersDict        = data.reduce( (acc, {key, ...value}) => ({ ...acc, [key]: {key, ...value} }), {} as ClustersDict);
const cluster: Clusters.Cluster    = values[key];

export {cluster, key, values};
